const surveyForm = document.querySelector('#homepageAnketa');

/**
 * Hide graphs
 */
const hideGraphs = () => {
    document.querySelectorAll('.anketa_graf').forEach((el) => {
        el.style.display = 'none';
    });
};

/**
 * Hide survey result percentage
 */
const hidePercentage = () => {
    document.querySelectorAll('.anketa_procenta').forEach((el) => {
        el.style.display = 'none';
    });
};

/**
 * Hide survey result percentage
 */
const hideOptions = () => {
    document.querySelectorAll('input[name="survey_option"]').forEach((el) => {
        el.style.display = 'none';
    });
};

/**
 * Reads cookie
 */
const readCookie = (name) => {
    let nameEQ = name + '=';
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
};

/**
 * Inits survey
 */
const initSurvey = (id, showBeforeVote, isFinished) => {
    let voted = readCookie('survey_id_' + id);

    if (!showBeforeVote && !isFinished && !voted) {
        hideGraphs();
        hidePercentage();
        document.querySelector('#q_status').style.display = 'none';
    }

    if (voted || isFinished) {
        document.querySelector('input[name="survey_submit"]').style.display = 'none';
        hideOptions();
    }
};

if (surveyForm) {
    const id = surveyForm.dataset.id;
    const showBeforeVote = surveyForm.getAttribute('data-show-before-vote');
    const isFinished = surveyForm.getAttribute('data-is-finished');
    if (id && showBeforeVote && isFinished) initSurvey(parseInt(id), parseInt(showBeforeVote), parseInt(isFinished));
}
